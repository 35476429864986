// extracted by mini-css-extract-plugin
export var Bold = "Histoire-module--Bold--D4LEU";
export var ContactLink = "Histoire-module--ContactLink--TJ2A-";
export var Contactlink = "Histoire-module--Contactlink--VuL9B";
export var DevelopmentWrapper = "Histoire-module--DevelopmentWrapper--gGKER";
export var LegalMentionsWrapper = "Histoire-module--LegalMentionsWrapper--X9iT9";
export var Link = "Histoire-module--Link--zx3sH";
export var NameValueWrapper = "Histoire-module--NameValueWrapper--yNEkN";
export var RowWrapper = "Histoire-module--RowWrapper--ely5z";
export var Wrapper = "Histoire-module--Wrapper--wXk3+";
export var link = "Histoire-module--link--sSR6h";